export enum TrackingEvent {
  BannerView = "view",
  OptInTouch = "opt_in_touch",
  OptOutTouch = "opt_out_touch",
  FilterTouch = "filter_touch",
  DismissTouch = "dismiss_touch",
  ProgramUrl = "program_url",
}

export const makeBannerTrackingName = (event: TrackingEvent) => {
  return `funding_program_banner_${event}`;
};
