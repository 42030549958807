import { Box, Button } from "@outschool/backpack";
import {
  FundingProgramAbbreviation,
  TrackingEvent,
  getFundingProgramProperties,
  makeBannerTrackingName,
} from "@outschool/funding-programs-shared";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { ExternalLink, useFeatureFlag } from "@outschool/ui-components-shared";
import { Banner } from "@outschool/ui-legacy-component-library";
import Cookies from "js-cookie";
import React, { useRef, useState } from "react";

export default function KansasKeepFundingBanner() {
  const ffShowBanner = useFeatureFlag(
    "esp_self-serve_invoicing_for_kansas_keep"
  );
  const { t } = useTranslation(
    "ui-components-website\\Banners\\KansasKeepFundingBanner"
  );
  const meritKeepSession = Cookies.get("meritKeepSession");
  const [isOptedIn, setIsOptedIn] = useState(!!meritKeepSession);

  const trackViewedRef = useRef<boolean>(false);
  const keepProgramAbbr = FundingProgramAbbreviation.KS;

  // Note: Hard-coding funding program here so tracking will reflect Kansas as
  // funding program even before opting-in
  const trackingProperties = React.useMemo(
    () => ({
      fundingProgram: keepProgramAbbr,
      component: "KansasKeepFundingBanner",
      isOptedIn,
    }),
    [keepProgramAbbr, isOptedIn]
  );

  const trackEvent = useTrackEvent();

  React.useEffect(() => {
    if (!trackViewedRef.current) {
      trackViewedRef.current = true;
      trackEvent(
        makeBannerTrackingName(TrackingEvent.BannerView),
        trackingProperties
      );
    }
  }, [trackEvent, trackingProperties]);

  if (!ffShowBanner) {
    return null;
  }

  if (meritKeepSession === "direct") {
    // No banner for users referred directly from Merit
    return null;
  }

  const fundingProgramProperties = getFundingProgramProperties(keepProgramAbbr);
  const programNameLong = fundingProgramProperties?.programNameLong;
  const programUrl = fundingProgramProperties?.programUrl || "#";

  const handleOptIn = () => {
    Cookies.set("meritKeepSession", "optIn");
    setIsOptedIn(true);
    trackEvent(
      makeBannerTrackingName(TrackingEvent.OptInTouch),
      trackingProperties
    );
  };

  const handleOptOut = () => {
    Cookies.remove("meritKeepSession");
    setIsOptedIn(false);
    trackEvent(
      makeBannerTrackingName(TrackingEvent.OptOutTouch),
      trackingProperties
    );
  };

  const handleClose = () => {
    trackEvent(
      makeBannerTrackingName(TrackingEvent.DismissTouch),
      trackingProperties
    );
  };

  return (
    <Banner
      data-test-id="keep-funding-banner"
      closable={true}
      onClose={handleClose}
      ref={trackViewedRef}
    >
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isOptedIn ? (
            <>
              <Button
                variant="link"
                sx={{
                  fontSize: "1em",
                  marginLeft: "0.5rem",
                }}
                onClick={handleOptOut}
              >
                {t("Exit {{programNameLong}} session", {
                  programNameLong,
                })}
              </Button>
              &nbsp;
              <Box>{t("to pay by other methods.")}</Box>
            </>
          ) : (
            <>
              <Box>
                {t("Would you like to use your {{programNameLong}} funds?", {
                  programNameLong,
                })}
              </Box>
              <Button
                variant="link"
                sx={{
                  fontSize: "1em",
                  marginLeft: "0.5rem",
                }}
                onClick={handleOptIn}
              >
                {t("Opt in")}
              </Button>
              &nbsp;
              <Box>{t("or")}</Box>
              &nbsp;
              <ExternalLink
                trackingName={makeBannerTrackingName(TrackingEvent.ProgramUrl)}
                trackingSharedProperties={trackingProperties}
                url={programUrl}
              >
                {t("learn more")}
              </ExternalLink>
            </>
          )}
        </Box>
      </Box>
    </Banner>
  );
}
